<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination m-0">
      <li
        class="page-item"
        :class="{
          disabled: disablePrevious,
        }"
        @click="prevPage"
      >
        <span class="page-link"><i class="fas fa-chevron-left"/></span>
      </li>
      <li
        class="page-item"
        v-if="showFirst"
        :class="{
          disabled: !showFirst,
        }"
        @click="setCurrentPage(1)"
      >
        <span class="page-link">1</span>
      </li>
      <li class="page-item" v-if="showFirst">
        <span class="page-link">...</span>
      </li>

      <li
        class="page-item"
        v-for="page in pagination.pages"
        :key="page"
        :class="{
          active: isCurrentPageEqualWith(page),
        }"
        @click="setCurrentPage(page)"
      >
        <span class="page-link">{{ page }}</span>
      </li>

      <li class="page-item" v-if="showLast">
        <span class="page-link">...</span>
      </li>
      <li
        class="page-item"
        v-if="showLast"
        :class="{
          disabled: !showLast,
        }"
        @click="setCurrentPage(totalPage)"
      >
        <span class="page-link">{{ totalPage }}</span>
      </li>
      <li
        class="page-item"
        :class="{
          disabled: disableNext,
        }"
        @click="nextPage"
      >
        <span class="page-link"><i class="fas fa-chevron-right"/></span>
      </li>
    </ul>
  </nav>
</template>
<style scoped>
.page-link {
  padding: 7.5px 15px;
  border-radius: 2rem !important;
  margin: 0 1px;
  border: none;
  cursor: pointer;
}
</style>
<script>
import { paginate } from "@/utils/paginate";
export default {
  props: {
    currentPage: {
      type: [Number, String],
      default: 1,
    },
    totalPage: {
      type: [Number, String],
      default: 0,
    },
    totalData: {
      type: [Number, String],
      default: 0,
    },
    maxPage: {
      type: [Number, String],
      default: 5,
    },
  },
  data() {
    const { currentPage, maxPage, totalPage, limit } = this;

    return {
      pagination: {
        currentPage: currentPage || 1,
        limit,
      },
      option: {
        current: currentPage || 1,
        total: totalPage || 0,
        max: maxPage || 5,
      },
    };
  },
  computed: {
    disablePrevious() {
      return !this.isTotalPageLessThan(0) && this.isCurrentPageEqualWith(1);
    },
    disableNext() {
      return (
        !this.isTotalPageLessThan(0) &&
        this.isCurrentPageEqualWith(this.option.total)
      );
    },

    showFirst() {
      return (
        !this.isTotalPageLessThan(0) &&
        (!this.isStartPageLessThan(2) || this.isStartPageEqualWith(2))
      );
    },

    showLast() {
      return (
        !this.isTotalPageLessThan(0) &&
        this.isEndPageLessThan(this.option.total - 1)
      );
    },
  },
  methods: {
    setCurrentPage(page) {
      const { pagination, option } = this;
      const { max, total } = option;

      this.pagination = {
        ...this.pagination,
        ...paginate({
          pageSize: +total,
          maxPages: +max,
          currentPage: +page,
        }),
      };

      if (pagination.currentPage === +page) {
        return;
      }

      this.onUpdatePage();
    },
    onUpdatePage() {
      this.$emit("change", this.pagination.currentPage);
    },

    prevPage() {
      if (this.disablePrevious) {
        return;
      }

      this.setCurrentPage(this.pagination.currentPage - 1);
    },

    isTotalPageLessThan(page) {
      return !(this.option.total > page);
    },

    isStartPageLessThan(page) {
      return !(this.pagination.startPage > page);
    },

    isEndPageLessThan(page) {
      return !(this.pagination.endPage > page);
    },

    isStartPageEqualWith(page) {
      return !!(this.pagination.startPage === page);
    },

    isCurrentPageEqualWith(page) {
      return !!(this.pagination.currentPage === page);
    },

    nextPage() {
      if (this.disableNext) {
        return;
      }

      this.setCurrentPage(this.pagination.currentPage + 1);
    },
  },
  mounted() {
    this.setCurrentPage(this.option.current);
  },
};
</script>
