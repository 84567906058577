<script>
export default {
  props: {
    title: String,
    description: String,
    icon: String,
    breadcrumbs: Array,
  },
};
</script>
<template>
  <div class="banner">
    <div class="banner__text">
      <div class="banner__breadcrumb">
        <template v-for="(breadcrumb, idx) in breadcrumbs">
          <component
            :is="breadcrumb.url ? 'router-link' : 'span'"
            :to="breadcrumb.url"
            :key="`label-${idx}`"
            :class="{ 'text-tertiary': idx + 1 !== breadcrumbs.length }"
          >
            {{ breadcrumb.label }}
          </component>
          <i
            class="fa fa-chevron-right"
            :key="`chevron-${idx}`"
            v-if="idx + 1 !== breadcrumbs.length"
          />
        </template>
      </div>
      <h1 class="text-primary font-weight-bold">
        {{ title }}
      </h1>
      <p class="text-muted">
        {{ description }}
      </p>
    </div>
    <div class="banner__decoration">
      <div class="position-relative">
        <img :src="icon" alt="" v-if="icon" />
        <img
          class="banner__decoration-1"
          src="@/assets/home/decoration/x.svg"
        />
        <img
          class="banner__decoration-2"
          src="@/assets/home/decoration/wave.svg"
        />
        <img
          class="banner__decoration-3"
          src="@/assets/home/decoration/dot.svg"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import "../../assets/styles/_breakpoint.scss";

.banner {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #f6fcff;
  min-height: 30vh;
  &__breadcrumb {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  &__text {
    padding: 1rem;
    max-width: 700px;
    z-index: 1;
    @include breakpoint("sm") {
      padding: 3rem;
    }
  }
  &__decoration {
    position: absolute;
    bottom: 0;
    right: 10%;
    z-index: 0;
    display: none;
    @include breakpoint("md") {
      display: block;
    }
    &-1 {
      position: absolute;
      left: -4rem;
      bottom: 50%;
      opacity: 0.5;
    }
    &-2 {
      position: absolute;
      top: -2rem;
      left: 2rem;
    }
    &-3 {
      position: absolute;
      right: -2rem;
      bottom: 50%;
      opacity: 0.7;
    }
  }
}
</style>
