var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{attrs:{"aria-label":"Page navigation example"}},[_c('ul',{staticClass:"pagination m-0"},[_c('li',{staticClass:"page-item",class:{
        disabled: _vm.disablePrevious,
      },on:{"click":_vm.prevPage}},[_vm._m(0)]),(_vm.showFirst)?_c('li',{staticClass:"page-item",class:{
        disabled: !_vm.showFirst,
      },on:{"click":function($event){return _vm.setCurrentPage(1)}}},[_c('span',{staticClass:"page-link"},[_vm._v("1")])]):_vm._e(),(_vm.showFirst)?_c('li',{staticClass:"page-item"},[_c('span',{staticClass:"page-link"},[_vm._v("...")])]):_vm._e(),_vm._l((_vm.pagination.pages),function(page){return _c('li',{key:page,staticClass:"page-item",class:{
        active: _vm.isCurrentPageEqualWith(page),
      },on:{"click":function($event){return _vm.setCurrentPage(page)}}},[_c('span',{staticClass:"page-link"},[_vm._v(_vm._s(page))])])}),(_vm.showLast)?_c('li',{staticClass:"page-item"},[_c('span',{staticClass:"page-link"},[_vm._v("...")])]):_vm._e(),(_vm.showLast)?_c('li',{staticClass:"page-item",class:{
        disabled: !_vm.showLast,
      },on:{"click":function($event){return _vm.setCurrentPage(_vm.totalPage)}}},[_c('span',{staticClass:"page-link"},[_vm._v(_vm._s(_vm.totalPage))])]):_vm._e(),_c('li',{staticClass:"page-item",class:{
        disabled: _vm.disableNext,
      },on:{"click":_vm.nextPage}},[_vm._m(1)])],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"page-link"},[_c('i',{staticClass:"fas fa-chevron-left"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"page-link"},[_c('i',{staticClass:"fas fa-chevron-right"})])
}]

export { render, staticRenderFns }