<template>
  <div>
    <banner
      title="Daftar Hasil Penilaian Buku "
      :breadcrumbs="breadcrumbs"
      :icon="require('@/assets/home/decoration/book.svg')"
    />

    <section id="content" class="mt-4">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="sidebar p-3">
              <div class="mb-3 mt-3">Filter berdasarkan field berikut :</div>
              <form @submit.prevent="getAssessment(true)">
                <div class="form-group">
                  <div for="" class="h6 font-weight-bold">Tipe</div>
                  <div
                    class="custom-control custom-radio"
                    v-for="option in typeOptions"
                    :key="option.value"
                  >
                    <input
                      type="radio"
                      :value="option.value"
                      :id="option.label"
                      v-model="filter.type"
                      class="custom-control-input"
                    />
                    <label :for="option.label" class="custom-control-label">{{
                      option.label
                    }}</label>
                  </div>
                </div>
                <div class="form-group">
                  <label for="" class="h6 font-weight-bold">Judul</label>
                  <input
                    v-model="filter.title"
                    type="text"
                    class="form-control search_table"
                    placeholder="Ketikan Judul Buku"
                  />
                </div>
                <div class="form-group">
                  <label for="" class="h6 font-weight-bold">Nama Penulis</label>
                  <input
                    v-model="filter.author"
                    type="text"
                    class="form-control search_table"
                    placeholder="Ketikan Nama Penulis"
                  />
                </div>
                <div class="form-group">
                  <label for="" class="h6 font-weight-bold"
                    >Nama Penerbit</label
                  >
                  <input
                    v-model="filter.publisher"
                    type="text"
                    class="form-control search_table"
                    placeholder="Ketikan Nama Penerbit"
                  />
                </div>

                <div class="form-group">
                  <label for="" class="h6 font-weight-bold">Peruntukan</label>
                  <div
                    class="custom-control custom-checkbox"
                    v-for="option in allotmentOptions"
                    :key="option.value"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :value="option.value"
                      :checked="option.value === filter.edu_stage"
                      @change="changeAllotment($event, option.value)"
                      :id="option.label"
                    />
                    <label class="custom-control-label " :for="option.label">
                      {{ option.label }}
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label for="" class="h6 font-weight-bold">SK Kelayakan</label>
                  <input
                    v-model="filter.no_sk"
                    type="text"
                    class="form-control search_table"
                    placeholder="SK Kelayakan"
                  />
                </div>
                <div class="d-flex justify-content-between">
                  <button
                    ref="loader"
                    @click="resetAssessment"
                    class="btn btn-outline-primary w-100  mr-1"
                    type="button"
                  >
                    Reset
                  </button>
                  <button
                    ref="loader"
                    @click="getAssessment(true)"
                    class="btn btn-primary w-100  ml-1"
                    type="submit"
                  >
                    Terapkan
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="col-md-9">
            <div
              v-if="$store.state.loadPage"
              style="height: 400px"
              class="d-flex justify-content-center align-items-center"
            >
              <img src="@/assets/loading-2.gif" class="img-fluid" alt="" />
            </div>

            <div v-else class="table-responsive">
              <table class="table result-table border" data-pagination="true">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">Kover</th>
                    <th scope="col">Judul Buku</th>
                    <th scope="col">Penulis</th>
                    <th scope="col">Penerbit</th>
                    <th scope="col">Kategori</th>
                    <th scope="col">Peruntukan</th>
                    <th scope="col">SK Kelayakan</th>
                    <th scope="col">Deskripsi</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(assessment, index) in assessments" :key="index">
                    <td>
                      <img
                        src="https://image.web.id/images/Dummy.png"
                        class="rounded"
                        style="width:48px"
                      />
                    </td>
                    <td>{{ assessment.title }}</td>
                    <td>{{ assessment.author }}</td>
                    <td>{{ assessment.publisher }}</td>
                    <td>{{ assessment.category }}</td>
                    <td>{{ assessment.edu_stage }}</td>
                    <td>{{ assessment.no_sk }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        data-toggle="popover"
                        title="Deskripsi"
                        :data-content="assessment.description"
                        v-if="assessment.description"
                      >
                        Lihat
                      </button>
                      <span v-else>
                        -
                      </span>
                    </td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <td colspan="99">
                      <div
                        class="d-flex justify-content-between align-items-center"
                        v-if="assessments.length"
                      >
                        <span>
                          Menampilkan {{ metadata.perPage }} dari
                          {{ formatNumber(metadata.total_rows) }} hasil
                        </span>

                        <pagination
                          class="m-0"
                          :current-page="pageNumber"
                          :total-data="metadata.total_rows"
                          :total-page="totalPage"
                          :key="totalPage"
                          :max-page="3"
                          @change="onChangePage"
                        />
                      </div>
                      <div v-else class="text-center">
                        Data tidak ditemukan
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <!-- row -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
.sidebar {
  border: 1px solid #dbddf0;
  border-radius: 0.5rem;
}
</style>

<script>
import axios from "axios";
import { BASE_URL } from "../../../store";

import Banner from "../../../components/homepage/Banner.vue";
import Pagination from "@/components/common/Pagination";

import $ from "jquery";

export default {
  components: { Banner, Pagination },
  name: "Penilaian",
  data() {
    return {
      breadcrumbs: [
        {
          label: "Beranda",
          url: "/",
        },
        {
          label: "Hasil Penilaian",
          url: "",
        },
      ],
      typeOptions: [
        {
          label: "Semua",
          value: "",
        },
        {
          label: "Buku Teks",
          value: "text",
        },
        {
          label: "Buku Non Teks",
          value: "nontext",
        },
      ],
      allotmentOptions: [
        {
          label: "PAUD",
          value: "paud",
        },
        {
          label: "SD/MI",
          value: "sd",
        },
        {
          label: "SMP/MTS",
          value: "smp",
        },
        {
          label: "SMA/MA/SMK/MAK",
          value: "smk",
        },
        {
          label: "SLB (SDLB/SMPLB/SMALB)",
          value: "slb",
        },
      ],
      pageNumber: 1,
      filter: {
        type: "",
        title: "",
        author: "",
        publisher: "",
        edu_stage: "",
        no_sk: "",
      },
      assessments: [],
      metadata: {
        perPage: 15,
        rowno: 0,
        total_rows: 0,
      },
    };
  },
  computed: {
    checkTotal() {
      if (this.$store.state.perPage == this.$store.state.totalRows) {
        return false;
      }
      return true;
    },
    totalPage() {
      return Math.ceil(this.metadata.total_rows / this.metadata.perPage);
    },
  },
  methods: {
    getAssessment(resetPage) {
      if (resetPage) {
        this.pageNumber = 1;
      }
      axios
        .get(`${BASE_URL}assessment/json/${this.pageNumber}`, {
          params: {
            "filter[assessment][type]": this.filter.type,
            "filter[assessment][title]": this.filter.title,
            "filter[assessment][author]": this.filter.author,
            "filter[assessment][publisher]": this.filter.publisher,
            "filter[assessment][edu_stage]": this.filter.edu_stage,
            "filter[assessment][no_sk]": this.filter.no_sk,
          },
        })
        .then((res) => {
          const { results, per_page, rowno, total_rows } = res.data;
          this.assessments = results;
          const metadata = {
            perPage: +per_page,
            rowno: +rowno,
            total_rows: +total_rows,
          };
          this.metadata = metadata;
        });
    },
    resetAssessment() {
      this.filter = {
        type: "",
        title: "",
        author: "",
        publisher: "",
        edu_stage: "",
        no_sk: "",
      };
      this.pageNumber = 1;
      this.getAssessment();
    },
    onChangePage(e) {
      this.pageNumber = e;
      this.getAssessment();
    },
    changeAllotment(e, val) {
      if (e.target.checked) {
        this.filter.edu_stage = val;
      } else {
        this.filter.edu_stage = "";
      }
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
  },
  created() {
    this.getAssessment();
  },
  mounted() {
    this.$nextTick(() => {
      $(function() {
        $('[data-toggle="popover"]').popover({
          container: "body",
        });
      });
    });
  },
};
</script>
